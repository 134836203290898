import React, { useCallback, useMemo } from 'react';
import { css } from '@emotion/core';
import { useTheme } from 'emotion-theming';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import Container from '@material-ui/core/Container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons';

import Layout from '../components/layout';
import SEO from '../components/helpers/seo';

import mq from '../styles/mq';
import useButtonStyle from '../hooks/useButtonStyle';
import useGutters from '../hooks/useGutters';

import { ImageType } from '../types/image';
import { ThemeType } from '../styles/theme';

export const query = graphql`
  {
    allContentfulProduct(limit: 1) {
      edges {
        node {
          slug
          title
          description {
            description
          }
          slogan
        }
      }
    }
    bottle: file(name: { eq: "bottle" }) {
      childImageSharp {
        fluid(maxWidth: 300, maxHeight: 700, cropFocus: CENTER, fit: COVER) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

interface HomeProps {
  data: {
    allContentfulProduct: {
      edges: {
        node: {
          slogan: string;
        };
      }[];
    };
    bottle: ImageType;
  };
}

const Home: React.FC<HomeProps> = ({ data }) => {
  const buttonStyle = useButtonStyle();
  const gutters = useGutters();
  const { color } = useTheme<ThemeType>();

  const products = useMemo(
    () => data.allContentfulProduct.edges.map((item) => item.node),
    [data]
  );

  const renderProducts = useCallback(
    () => (
      <ul
        css={css`
          padding: 100px 0 50px;

          ${mq(`md`)} {
            padding: 0;
          }
        `}
      >
        {products?.map((product) => (
          <li key={product.id}>
            <article
              css={css`
                text-align: center;
                padding: 0 20px;

                ${mq(`md`)} {
                  padding: 0 20px 0 90px;
                  text-align: left;
                }

                ${mq(`lg`)} {
                  padding-left: 120px;
                }

                ${mq(`xl`)} {
                  padding-left: 100px;
                }
              `}
            >
              <p
                css={css`
                  color: ${color.warning};
                  font-size: 20px;
                  font-weight: 900;
                `}
              >
                2020
              </p>
              <h2
                css={css`
                  color: ${color.primary};
                  font-size: 40px;
                  font-weight: 900;
                  margin: 0 auto 20px;
                  line-height: 1;
                  max-width: 400px;

                  ${mq(`md`)} {
                    font-size: 50px;
                  }

                  ${mq(`lg`)} {
                    font-size: 60px;
                  }

                  ${mq(`xl`)} {
                    font-size: 80px;
                    margin: initial;
                  }
                `}
              >
                {product?.title}
              </h2>
              <h3
                css={css`
                  color: ${color.primary};
                  font-size: 20px;
                  font-weight: 800;
                  margin-bottom: 50px;
                  text-transform: uppercase;
                `}
              >
                {product?.slogan}
              </h3>
              <Link
                to={`/bieres/${product?.slug}`}
                css={css`
                  ${buttonStyle}
                `}
              >
                Découvrez
              </Link>
            </article>
          </li>
        ))}
      </ul>
    ),
    []
  );

  return (
    <Layout footer>
      <SEO
        title="Bière Belge bio"
        description="Les 3 amis ont voulu retrouver le gout de la bière de leur jeunesse, un certain mélange entre la vieux temps et la Leffe."
      />
      <section
        css={css`
          position: relative;

          ${mq(`md`)} {
            background: ${color.primary};
            background: linear-gradient(
              90deg,
              ${color.primary} 0%,
              ${color.primary} 49%,
              ${color.white} 51%,
              ${color.white} 100%
            );
          }
        `}
      >
        <Container disableGutters={gutters}>
          <div
            css={css`
              display: flex;
              flex-direction: column;

              ${mq(`md`)} {
                flex-direction: row;
                height: 100vh;
              }

              & > div {
                flex: 0 0 50%;

                ${mq(`md`)} {
                  display: flex;
                  align-items: center;
                  max-width: 50%;
                }
              }
            `}
          >
            <div
              css={css`
                background-color: ${color.primary};
                text-align: center;
                padding: 125px 0;

                ${mq(`md`)} {
                  padding: 125px 100px 125px 0;
                  text-align: left;
                }

                ${mq(`lg`)} {
                  padding: 125px 0;
                }
              `}
            >
              <div
                css={css`
                  padding: 0 20px;

                  ${mq(`md`)} {
                    padding: 0;
                  }
                `}
              >
                <h1
                  css={css`
                    color: ${color.white};
                    font-size: 60px;
                    font-weight: 900;
                    line-height: 1;
                    margin-bottom: 40px;
                    text-transform: uppercase;

                    ${mq(`md`)} {
                      font-size: 70px;
                    }

                    ${mq(`lg`)} {
                      font-size: 80px;
                    }

                    ${mq(`xl`)} {
                      font-size: 120px;
                    }
                  `}
                >
                  Bière
                  <br />
                  Belge
                </h1>
                <p
                  css={css`
                    color: ${color.white};
                    font-size: 16px;
                    font-weight: 600;
                    margin: 0 auto 50px;
                    opacity: 0.8;
                    max-width: 350px;

                    ${mq(`md`)} {
                      margin: 0 0 50px;
                    }
                  `}
                >
                  Les 3 amis ont voulu retrouver le gout de la bière de leur
                  jeunesse, un certain mélange entre la vieux temps et la Leffe.
                </p>
                <Link
                  to="/notre-histoire"
                  css={css`
                    ${buttonStyle}
                  `}
                >
                  Découvrez
                </Link>
              </div>
            </div>
            <div
              css={css`
                background-color: ${color.white};
                justify-content: center;
              `}
            >
              <div>{renderProducts()}</div>
            </div>
          </div>
        </Container>
        <figure
          css={css`
            width: 200px;
            height: auto;
            margin: 0 auto 50px;

            ${mq(`md`)} {
              width: 200px;
              height: 470px;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              margin: 0;
            }

            ${mq(`lg`)} {
              width: 250px;
              height: 585px;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }

            ${mq(`xl`)} {
              width: 300px;
              height: 700px;
              top: 40%;
              left: 50%;
              transform: translate(-50%, -40%);
            }
          `}
        >
          <Img fluid={data.bottle.childImageSharp.fluid} />
        </figure>
        <div
          css={css`
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            padding-bottom: 75px;
          `}
        >
          <Container>
            <div
              css={css`
                display: flex;

                a {
                  margin-right: 30px;
                }
              `}
            >
              <a
                href="https://www.facebook.com/3D.beerBelgium"
                target="_blank"
                rel="noopener noreferrer"
                title="Facebook"
              >
                <FontAwesomeIcon icon={faFacebookF} color="white" size="lg" />
              </a>
              <a
                href="https://www.instagram.com/3d.beer/"
                target="_blank"
                rel="noopener noreferrer"
                title="Instagram"
              >
                <FontAwesomeIcon icon={faInstagram} color="white" size="lg" />
              </a>
            </div>
          </Container>
        </div>
      </section>
    </Layout>
  );
};

export default Home;
