import { useCallback, useEffect, useState } from 'react';

const useGutters = () => {
  const [gutters, setGutters] = useState<boolean>(false);

  const onResize = useCallback(() => {
    if (window.innerWidth < 768) {
      setGutters(true);
    } else {
      setGutters(false);
    }
  }, []);

  useEffect(() => {
    onResize();

    window.addEventListener(`resize`, onResize);

    return () => window.removeEventListener(`resize`, onResize);
  }, [onResize]);

  return gutters;
};

export default useGutters;
